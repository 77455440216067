// mat-for-field mat-setect
.mat-form-field {
  background-color: $color-background-neutral-subtlest;
  border-radius: $radius-100;
  padding: $space-100 $space-150;
  height: 40px;
  color: $color-text-primary;

  .mat-select-trigger {
    padding: 0;
  }

  .mat-select {
    height: 100%;

    .mat-select-trigger {
      padding: 0;
    }

    .mat-select-arrow-wrapper .mat-select-arrow::before {
      color: $color-icon-subtle;
    }

    .mat-select-placeholder,
    .mat-select-value {
      @include font($font-body, true);
    }
  }
}

// input
.uni-input,
input.mat-input,
input.uni-input {
  border: 1px solid #d0d5ce;
  background-color: $color-background-input-default;
  border-radius: $radius-100;
  padding: $space-100 $space-150;
  height: 40px;
  color: $color-text-primary;
  @include font($font-body, true);

  &::placeholder {
    color: $color-dark-light !important;
    -webkit-text-fill-color: $color-dark-light !important;
    font-weight: inherit;
    font-size: inherit;
  }
}

.uni-form-field__prefix {
  color: #a3ad9e;
  top: 20px !important;
}
