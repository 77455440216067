// @import "styles";

.cursor-pointer {
  cursor: pointer;
}

.int-modal {
  &__content {
    border-radius: $radius-150 !important;
    padding: 0 !important;

    .modal {
      &-header {
        padding: $space-250;
      }

      &-content {
        padding: $space-250;
        border-radius: 0;
        border: none;
      }

      &-footer {
      }
    }
  }
}

.ngx-json-viewer {
  .segment {
    padding: 0 !important;
    .segment-main {
      .segment-key,
      .segment-separator,
      .segment-value {
        color: $color-text-primary !important;
        line-height: 16px !important;
      }
    }
  }
}

uni-box-tab {
  transition: none !important;
}

.circle-info i:before {
  color: $color-neutral-300 !important;
  margin-left: $space-050;
}
