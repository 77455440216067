/* You can add global styles to this file, and also import other style files */
@import "styles/global";
@import "styles/styles";

.header-link {
  cursor: pointer;
  transition: 0.2s all ease-in-out;

  &:hover {
    color: $color-brand-green;
  }
}
